/**
 * Imports
 */
window.$ = $ = require('jquery')

/**
 * Elements
 */
let people = []
let boosts = []
let animations = ['slideInLeft', 'slideInRight', 'slideInUp', 'slideInDown']

const hero = $('.hero')
const heroOpen = $('.grid__up')
const heroClose = $('.hero__cta')
const grid = $('.grid__people')
const modals = $('.modals')
const modalLayer = $('.modal__layer')

/**
 * Storage actions
 */
Storage.prototype.setObj = function(key, obj) {
    return this.setItem(key, JSON.stringify(obj))
}

Storage.prototype.getObj = function(key) {
    return JSON.parse(this.getItem(key))
}

let store = window.localStorage

if (!store.getObj('boosted')) {
    store.setObj('boosted', [])
}

/**
 * Functions
 */
function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

function addAnims() {
    if ($(window).width() > 960) {
        $('.person').each(function () {
            $(this).addClass(`animated ${animations[getRandomInt(0, 3)]}`)
        })
    }
}

function removeAnims() {
    if ($(window).width() > 960) {
        $('.person').removeClass('animated slideInLeft slideInRight slideInUp slideInDown')
    }
}

function openHero() {
    $('html, body').animate({ scrollTop: 0 }, 250)

    if ($(window).width() > 960) {
        removeAnims()
    }
    
    hero.addClass('animated fadeIn')
}

function hideHero() {
    $('html, body').animate({ scrollTop: hero.height() }, 250)

    if ($(window).width() > 960) {
        addAnims()
    }

    hero.removeClass('animated fadeIn')

    setTimeout(() => {
        removeAnims()
    }, 1000)
}

function openModal(id) {
    if (!id) {
        return false
    }

    let modal = $(`#modal-${id}`)

    modal.addClass('is-open animated fadeIn')
    modalLayer.fadeIn(250)
}

function closeModals() {
    $('.modal').removeClass('is-open animated fadeIn')
    modalLayer.fadeOut(250)
}

function boostUser(id) {
    if (!isBoosted(id)) {
        let obj = store.getObj('boosted')
        obj.push(id)

        // Write to localStorage
        store.setObj('boosted', obj)

        // Change DOM
        $(`#modal-${id}`).find('.modal__links__boost').hide()
        $(`#modal-${id}`).find('.modal__links__boosted').fadeIn(250)
        $(`#person-${id} .person__boost`).html(parseInt($(`#person-${id} .person__boost`).text()) + 1)

        // Write to file
        $.ajax({
            method: 'get',
            url: '/boost.php',
            data: {
                id: id,
                boost: $(`#person-${id} .person__boost`).text()
            }
        }).done(function (result) {
            console.log(`Person #${id} boosted.`)
        }).catch(function (error) {
            console.warn(error)
        })
    }
}

function isBoosted(id) {
    return store.getObj('boosted').indexOf(id) === -1 ? false : true
}

/**
 * Events
 */
$(window).scroll(function () {
    if ($(window).scrollTop() > ($(window).height() * 0.8)) {
        if (!$('.person:first').hasClass('animated')) {
            addAnims()
        }
    }
 })

$(function () {
    $('html').removeClass('no-js')
    $('html, body').animate({ scrollTop: 0 }, 250)

    if (heroOpen.length) {
        heroOpen.on('click', () => openHero())
    }

    if (heroClose.length) {
        heroClose.on('click', () => hideHero())
    }

    if (grid.length) {
        $.ajax({
            method: 'get',
            url: '/people.json'
        }).done(result => {
            boosts = result
        }).then(() => {
            $.ajax({
                method: 'get',
                url: '/data.json'
            }).done(result => {
                let colors = ['cyan', 'yellow', 'pink']
                let gridContent = ''
                let modalContent = ''
    
                people = result
    
                result.map((person, index) => {
                    gridContent += `<div class="person person--${colors[getRandomInt(0, 2)]}" id="person-${person.id}" data-person="${person.id}">
                                        <span class="btn btn--boost person__boost">${boosts[index]['boosts']}</span>
                                        <div class="person__image">
                                            <img src="img/people/${person.slug}-ff.jpg" alt="${person.name}" title="${person.name}">
                                            <img src="img/people/${person.slug}.jpg" alt="${person.name}" title="${person.name}">
                                        </div>
                                    </div>`
    
                    modalContent += `
                        <div class="modal" id="modal-${person.id}">
                            <span class="modal__close"></span>
                    
                            <div class="modal__content">
                                <div class="modal__image">
                                    <img src="img/people/${person.slug}.jpg" alt="${person.name}" title="${person.name}">
                                </div>
                    
                                <h2 class="modal__title">${person.name}</h2>
                                <h6 class="modal__subtitle">${person.title}</h6>
                    
                                <p>${person.lead}</p>
                    
                                <ul class="modal__list">
                                    ${person.links.map((link, i) => 
                                        `<li><a href="${link.url}" target="_blank" rel="noopener">${link.title}</a></li>`
                                    .trim()).join('')}
                                </ul>
                    
                                <div class="modal__controls">
                                    <div class="modal__links">
                                        ${isBoosted(person.id) ?
                                        `
                                            <span class="thanks">Köszönöm<br>a támogatásod!</span>
                                            <button type="button" class="btn btn--primary btn--boost">${boosts[index]['boosts']}</button>`
                                        :
                                        `
                                            <div class="modal__links__boosted" style="display: none;">
                                                <span class="thanks">Köszönöm<br>a támogatásod!</span>
                                                <button type="button" class="btn btn--primary btn--boost">${parseInt(boosts[index]['boosts']) + 1}</button>
                                            </div>
    
                                            <div class="modal__links__boost" style="display: block;">
                                                <div><a href="#/" data-boost="true" data-user="${person.id}">Buzdításhoz nyomj ide!</a></div>
                                                <button type="button" class="boost" data-boost="true" data-user="${person.id}"></button>
                                                <div><span class="or">vagy</span></div>
                                            </div>
                                        `}
                                        <div><a href="#/" data-modal-close="true">Ismerd meg, mivel készülnek 2019-re a többiek!</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `
                })
    
                grid.html(gridContent)
                modals.html(modalContent)
    
                $('[data-boost]').on('click', function () {
                    boostUser($(this).data('user'))
                })
    
                $('.person').on('click', function(e) {
                    e.preventDefault()
                    openModal($(this).data('person'))
                })
    
                $('.modal__layer, .modal__close, [data-modal-close]').on('click', () => closeModals())
    
                $(window).keyup(function (e) {
                    if (e.keyCode === 27) {
                        closeModals()
                    }
                })
            })
        })
    }
})
